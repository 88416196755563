/**
 * @author Hem Chudgar
 */
import { Injectable } from '@angular/core';
import { from } from 'rxjs/internal/observable/from';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';


/**
 * AuthService
 */
@Injectable()
export class AuthService {

  /** currentUserData */
  private currentUserData: BehaviorSubject<any>;


  constructor() {
    this.currentUserData = new BehaviorSubject<any>(null);
  }

  /**
   * setUserData
   * @param user
   */
  public setUserData(user: any): void {
    this.currentUserData.next(user);
  }

  /**
   * getUserData
   */
  public getUserData(): Observable<any> {
    return this.currentUserData.asObservable();
  }

  /**
   * isLoggedInObs
   */
  // public isLoggedInObs(): Observable<boolean> {
  //   return from(.then((user: User) => {
  //     if (user) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   }));
  // }
}
