/**
 * @author Hem Chudgar
 */
import { tap, finalize, catchError } from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import {
  HttpRequest, HttpHandler, HttpInterceptor, HttpSentEvent, HttpHeaderResponse, HttpProgressEvent,
  HttpResponse, HttpUserEvent, HttpEvent, HttpErrorResponse
} from '@angular/common/http';
// --------------------------------------------------- //
import { LoaderService } from '../loader/loader.service';
import { AuthService } from '../auth/auth.service';
import { ToastrServiceProvider } from '../toaster/toastr.service';
import { BusyService } from './busy.service';
import { throwError } from 'rxjs/internal/observable/throwError';
import { Observable } from 'rxjs/internal/Observable';
import { MsalService } from '@azure/msal-angular';

/**
 * AuthInterceptor
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  /** to keep count of current executing requests */
  private totalRequests: number;
  private accessToken: any;
  constructor(
    private loaderService: LoaderService,
    private authService: AuthService,
    private _toastr: ToastrServiceProvider,
    private busyService: BusyService,
    private msalsrv: MsalService,
    @Inject('environment') private environment: any
  ) {
    this.totalRequests = 0;
  }

  /**
   * intercept
   * @param request
   * @param next
   */
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse |
    HttpProgressEvent | HttpResponse<object> | HttpUserEvent<object>> {
    const accessTokenRequest = {
      scopes: this.environment.scope,
      account: this.msalsrv.instance.getAllAccounts()[0],
    };
    if (!this.accessToken) {
      this.msalsrv.acquireTokenSilent(accessTokenRequest).subscribe((accessTokenReponse) => {
        if (accessTokenReponse != null) {
          // Acquire token silent success
          this.accessToken = accessTokenReponse.accessToken;
          // Call your API with token

        }
      })
    }

    this.totalRequests++;
    this.loaderService.showLoader(true);


    if (request.url.includes('/documents/')) {
      this.loaderService.showLoader(false);
    }

    if (request.headers.get('No-auth') === 'true') {
      return this.handleRequest(request, next);
    }

    request = request.clone({
      setHeaders: {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        Authorization: `Bearer ${this.accessToken}`,
      }
    });

    return this.handleRequest(request, next);
  }

  /**
   * Handle Http request
   * @param request Http request
   * @param next Http handler
   */
  public handleRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {

        // set action busy if request sent
        if (event.type === 0) {
          this.busyService.changeBusy(true)
        } else {
          this.busyService.changeBusy(false)
        } 
        if (request.url.includes('digitalMails/action/')) {
          if (event instanceof HttpResponse && event.status === 200) {
            if (event.body.result && isNaN(event.body.result) && typeof (event.body.result) === 'string') {
              // this._toastr.success(event.body.result, null)
            }
          }
          else {
            if (event instanceof HttpResponse && event.status === 200) {
              if (event.body.result && isNaN(event.body.result) && typeof (event.body.result) === 'string') {
                this._toastr.success(event.body.result, null)
              }
            }
          }
        }
      }),
      /** handles the various Error Response. */
      catchError((errorResponse: HttpErrorResponse) => {
        if (errorResponse instanceof HttpErrorResponse) {
          // ERROR HANDLING HEREs
          if (errorResponse.status === 400) {
            const errors = errorResponse.error.hasOwnProperty('errors') ? errorResponse.error['errors'] : errorResponse.error;
            this._toastr.error(errors);
          } else if (errorResponse.status === 401) {
            this._toastr.error('Unauthorized Access');
          } else if (errorResponse.status === 403) {
            this._toastr.error('Access Denied');
          } else if (errorResponse.status === 404) {
            this._toastr.error('End Point Not Found');
          } else if (errorResponse.status === 408) {
            this._toastr.error('Request Timeout');
          } else if (errorResponse.status === 500) {
            this._toastr.error('Internal Server Error');
          }
        }
        return throwError(errorResponse);
      }),
      finalize(() => {
        this.totalRequests--;
        if (this.totalRequests === 0) {
          this.loaderService.showLoader(false);
        }
        this.busyService.changeBusy(false);
      }));
  }
}
