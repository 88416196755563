/**
 * Policy Model
 */
export class PolicyData {
    /**
     * Role(s) definde ins Policy Data
     */
    public roles: string | string[];
    /**
     * Permission(s) definde in Policy Data
     */
    public permissions: string | string[];

    // tslint:disable-next-line:no-any
    constructor (data?: any) {
        if (data) {
            this.roles = data.roles;
            this.permissions = data.permissions;
        }
    }
}
