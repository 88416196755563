/**
 * @author
 */


import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {  AuthGuard, LogoutComponent } from 'common-libs';
import { MasterComponent } from './core/components/master/master.component';
import { ActivateDashboardGuard } from './core/guards/activate-dashboard/activate-dashboard.guard';
import { CheckUserProfileGuard } from './core/guards/check-user-profile.guard';
import { AppResolverService } from './core/resolvers/app.resolver';
import { UserInfoResolverService } from './core/resolvers/user-info.resolver';
import { UserProfileResolverService } from './core/resolvers/user-profile.resolver';
import { NotFoundComponent } from './unauthorized/not-found.component';
import { AuthCallbackPolicyComponent } from 'projects/common-libs/src/lib/core/component/auth-callback/auth-callback-policy.component';
import { MsalGuard } from '@azure/msal-angular';


const routes: Routes = [
  // {
  //   path: 'auth-callback',
  //   component: AuthCallbackPolicyComponent
  // },
  {
    path: '',
    // component: MasterComponent,
    resolve: {
      policy: AppResolverService,
      userInfo: UserInfoResolverService,
    },
    canActivate: [
      MsalGuard
    ],
    canActivateChild: [AuthGuard,MsalGuard],
    children: [
      {
        path: 'archive',
        canActivate: [CheckUserProfileGuard],
        data: { isArchived: true },
        children: [
          {
            path: '',
            redirectTo: 'document',
            pathMatch: 'full'
          },
          {
            path: 'document',
            canActivate: [ActivateDashboardGuard],
            loadChildren: () => import('./document/document.module').then(m => m.DocumentModule),
            data: {
              title: 'Dashboard',
              breadcrumb: 'Dashboard',
            },
          },
          // {
          //   path: 'report',
          //   loadChildren: () => import('./report/report-archived.module').then(m => m.ReportArchivedModule),
          //   data: { title: 'Report' },
          // }
        ]
      },
      {
        path: '',
        canActivate: [CheckUserProfileGuard],
        children: [
          {
            path: '',
            redirectTo: 'document',
            pathMatch: 'full'
          },
          {
            path: 'document',
            canActivate: [ActivateDashboardGuard],
            loadChildren: () => import('./document/document.module').then(m => m.DocumentModule),
            data: {
              title: 'Dashboard',
              breadcrumb: 'Dashboard',
            },
          },
          // {
          //   path: 'report',
          //   loadChildren: () => import('./report/report.module').then(m => m.ReportModule),
          //   data: { title: 'Report' },
          // },
        ]
      },
      // {
      //   path: 'user-profile',
      //   resolve: { userProfile: UserProfileResolverService },
      //   data: { breadcrumb: 'User Profile' },
      //   loadChildren: () => import('./user-profile/user-profile.module').then(m => m.UserProfileModule),
      // },
    ]
  },
  {
    path: 'unauthorized',
    component: NotFoundComponent
  },
  {
    path: 'page-not-found',
    component: NotFoundComponent
  },
  {
    path: 'inactive-user',
    component: NotFoundComponent
  },
  {
    path: 'no-license',
    component: NotFoundComponent
  },
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: '**',
    redirectTo: 'page-not-found',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
