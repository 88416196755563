import { Injectable } from '@angular/core';
// ------------------------------------------------------------------------ //
import { IBreadcrumb } from './breadcrumb.shared';
import { Observable } from 'rxjs/internal/Observable';

export interface IPostProcessFunc {
  (crumbs: IBreadcrumb[]): Promise<IBreadcrumb[]> | Observable<IBreadcrumb[]> | IBreadcrumb[];
}

@Injectable()
export class BreadcrumbConfig {
  public postProcess: IPostProcessFunc;
}
