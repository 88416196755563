/**
 * Environment configurations for Policy Module
 */
export class PolicyEnvironment {
  /** Endpoint URL */
  public url: string;
  /** Client_ID / App_ID */
  public client_id: string;
  /** Policy name associate with Clien_ID / App_ID */
  public policy_name: string;
  /** Storage type */
  public storageType?: StorageType;
  public policy_url?: string;
}

/**
 * Enum for Storage type
 */
export enum StorageType {
  /** Storage type localStorage */
  localStorage = 'localStorage',
  /** Storage type sessionStorage */
  sessionStorage = 'sessionStorage',
  /** Default Storage type */
  default = 'sessionStorage',
}
