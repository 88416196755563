// import { BehaviorSubject, Observable, isObservable } from 'rxjs/index';
// import { tap, first } from 'rxjs/internal/operators';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';

import { PolicyData } from '../model/policy-data.model';
import { PolicyEnvironment, StorageType } from '../model/policy-environment.model';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { isObservable } from 'rxjs/internal/util/isObservable';
import { Observable } from 'rxjs/internal/Observable';
import { tap } from 'rxjs/internal/operators/tap';
import { first } from 'rxjs/internal/operators/first';

export const AUTH_POLICY_ENV = new InjectionToken<Array<() => void>>('AuthPolicyEnv');

/**
 * Auth Policy service
 */
@Injectable()
export class AuthPolicyService {

  /** Storage key prefix */
  private readonly POLICY_DATA_KEY: string = 'user_policy';

  /**
   * Policy Model Data
   */
  private _policyData: BehaviorSubject<PolicyData>;

  /** Policy Environment */
  private _policyEnv: PolicyEnvironment

  /**
   * Storage Type
   */
  // tslint:disable-next-line:no-any
  private storage: any;
  private environment:any

  constructor(
    @Inject('environment')environment,
    @Inject(AUTH_POLICY_ENV)  // InjectionToken
    @Optional()               // Optional arg
    private config: any,
    private _http: HttpClient
  ) {
    this.environment = environment
    if (environment) {
      if (isObservable(environment)) {
        environment = environment.pipe(first()).toPromise();
      }
      if (this.isPromise(environment)) {
        (environment as Promise<PolicyEnvironment>)
          .then((configFromPromise) => {
            this._policyEnv = configFromPromise;
            this.initializePolicyEnv();
          }).catch(e => { console.error(e); });
      } else {
        this._policyEnv = environment;
        this.initializePolicyEnv();
      }
    }

  }

  /**
   * Get current policy data
   * @returns Observable of PolicyData
   */
  public getPolicyData(): Observable<PolicyData> {
    return this._policyData;
  }

  /**
   * Checks specified role exists in current policy data or not
   * @param roleName Name of role to find in current policy data
   * @returns boolean
   */
  public isInRole(roleName: string): boolean {
    const currentPolicyData: PolicyData = this._policyData.getValue();
    return currentPolicyData && currentPolicyData.hasOwnProperty('roles') &&
      currentPolicyData.roles.indexOf(roleName) >= 0 ? true : false;
  }

  /**
   * Checks specified permission exists in current policy data or not
   * @param permissionName Name of permission to find in current policy data
   * @returns boolean
   */
  public hasPermission(permissionName: string): boolean {
    const currentPolicyData: PolicyData = this._policyData.getValue();
    return currentPolicyData && currentPolicyData.hasOwnProperty('permissions') &&
      currentPolicyData.permissions.indexOf(permissionName) >= 0 ? true : false;
  }

  /**
   * Hit an endpoint and load and store policy data
   * @param httpHeaders Http Headers
   * @param options Any request Options
   * @returns Observable <PolicyData>
   */
  // tslint:disable-next-line:no-any
  public loadPolicyData(httpHeaders?: HttpHeaders, options?: any): Observable<PolicyData> {
    let headers: HttpHeaders = new HttpHeaders();
    if (httpHeaders) {
      headers = httpHeaders;
    }
    return this.loadPolicy(this._policyEnv.policy_url, headers, options);

    // return this.loadPolicy();
  }

  /**
   * Remove loaded policy data from specified storage
   * @requires Promise<boolean>
   */
  public unLoadPolicyData(): Promise<boolean> {
    // tslint:disable-next-line:typedef
    return new Promise((resolve) => {
      this.storage.removeItem(this.getStorageKey());
      this._policyData.next(new PolicyData());
      //TODO: this._policyData.next(null);
      resolve(true);
    });
  }

  /**
   * Bind query params to URL and return the http request
   * @param url URL endpoint path
   * @param clientId Client_ID
   * @param policyName Policy Name
   * @param headers Http Headers
   * @param options Any request Options
   * @returns Observable<PolicyData>
   */
  // tslint:disable-next-line:no-any
  private loadPolicy(url?: string, headers?: HttpHeaders, options?: any): Observable<PolicyData> {

    let httpExtras: object = { headers };
    if (options) {
      httpExtras = { headers, ...options };
    }
    return this._http.get<PolicyData>(url, httpExtras)
      // Save policy data
      .pipe(
        tap((policyData: PolicyData) => this.setPolicyData(policyData)),
      );
  }

  /**
   * Parse and set policy data to storage and update the Observable for policy data
   * @param data Policy Data
   * @returns void
   */
  public setPolicyData(data: PolicyData): void {
    const policy: PolicyData = new PolicyData(data);
    this.storage.setItem(this.getStorageKey(), JSON.stringify(policy));
    this._policyData.next(policy);
  }

  /**
   * Generates client wise unique key for maintaning storage
   * @returns string
   */
  private getStorageKey(): string {
    return `${this.POLICY_DATA_KEY}:${this._policyEnv.client_id}`;
  }

  /**
   * Set Storage type for policy data
   * @param config Environment configuration
   * @returns Storage
   */
  private setStorageType(config?: StorageType): Storage {
    let storageType: Storage;
    switch (config) {
      case StorageType.localStorage:
        storageType = localStorage;
        break;
      case StorageType.sessionStorage:
        storageType = sessionStorage;
        break;
      default:
        storageType = sessionStorage;
        break;
    }
    return storageType;
  }

  /**
   * Initialize policy environment
   * @returns void
   */
  private initializePolicyEnv(): void {
    this.storage = this.setStorageType(this._policyEnv.storageType || StorageType.default);
    // Load Policy data from storage
    const storage: object = JSON.parse(this.storage.getItem(this.getStorageKey())) || null;
    this._policyData = new BehaviorSubject<PolicyData>(new PolicyData(storage));



  }

  /**
   * Checks if the argument is a function or promiss
   * @param obj Object | Promiss
   */
  private isPromise(obj: any): obj is Promise<any> {
    return !!obj && typeof obj.then === 'function';
  }
}
