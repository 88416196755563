/**
 * This enum is use to define client property.
 */
export enum SuperManager {
    view = 'SUPERMANAGER_VIEW',
}

/**
 * This enum is use to define client property.
 */
export enum Document {
    superUser = 'SUPER_USER',
    manager = 'MANAGER',
    associate = 'ASSOCIATE',
    requestor = 'REQUESTOR'
}


/** Available roles for policy */
export enum PolicyRoles {
    superUser = 'SUPER_USER',
    manager = 'MANAGER',
    associate = 'ASSOCIATE',
    requestor = 'REQUESTOR'
}

/**
 * This enum is use to define client property.
 */
export enum User {
    view = 'USER_VIEW',
    add = 'USER_ADD',
    update = 'USER_UPDATE',
    delete = 'USER_DELETE',
    changeStatus = 'USER_CHANGE_STATUS',
    bulkUpload = 'USER_BULK_UPLOAD',
    changePassword = 'USER_CHANGE_PASSWORD'
}

// tslint:disable-next-line: typedef
export const Permission = {
    SuperManager,
    Document,
    User
}