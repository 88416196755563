import { A11yModule } from '@angular/cdk/a11y';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbAccordionModule, NgbDropdownModule, NgbTimeAdapter, NgbTimepickerModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
// ------------------------------------------------------------------------- //
import { NgbTimeStringAdapter, SharedModule } from 'common-libs';
// ------------------------------------------------------------------------- //
import { getDatepickerConfig, getPopoverConfig } from '../core/utility/utility';
import { FocusInvalidInputDirective } from './directives/focus-invalid-input/focus-invalid-input.directive';
import { OnlyNumberDirective } from './directives/only-number/only-number.directive';
import { OnlyStringDirective } from './directives/only-string/only-string.directive';
import { PreventTextDirective } from './directives/prevent-text/prevent-text.directive';
import { InputTrimDirective } from './directives/trim/trim.directive';
import { NgxPrintModule } from './modules/ngx-print/ngx-print.module';
import { TextSearchPipe } from './pipe/text-search';
import { TimeFormatPipe } from './pipe/time-format.pipe';

@NgModule({
    declarations: [
        TimeFormatPipe,
        OnlyNumberDirective,
        OnlyStringDirective,
        InputTrimDirective,
        PreventTextDirective,
        FocusInvalidInputDirective,
        TextSearchPipe
    ],
    imports: [
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        BsDropdownModule,
        NgbDropdownModule,
        PortalModule,
        OverlayModule,
        TimepickerModule.forRoot(),
        PopoverModule.forRoot(),
        BsDatepickerModule.forRoot(),
        NgxPrintModule,
        NgbTimepickerModule,
        A11yModule,
        TabsModule,
        CommonModule,
        NgbTooltipModule,
        TooltipModule,
        NgbAccordionModule,
    ],
    exports: [
        SharedModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        BsDatepickerModule,
        PopoverModule,
        PortalModule,
        OverlayModule,
        NgxPrintModule,
        NgbDropdownModule,
        TimeFormatPipe,
        BsDropdownModule,
        OnlyNumberDirective,
        OnlyStringDirective,
        InputTrimDirective,
        PreventTextDirective,
        FocusInvalidInputDirective,
        TabsModule,
        NgbTimepickerModule,
        NgbTooltipModule,
        TooltipModule,
        NgbAccordionModule,
        TextSearchPipe
    ],
    providers: [
        // { provide: PopoverConfig, useFactory: getPopoverConfig },
        // { provide: BsDatepickerConfig, useFactory: getDatepickerConfig },
        { provide: NgbTimeAdapter, useClass: NgbTimeStringAdapter },
    ]
})
export class AppSharedModule { }
