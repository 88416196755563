export const environment = {
  production: true,
  /// 1Authority config
  client_id: '1TrakItFMUserPortal',
  client_key: '#{CLIENT_KEY}#',
  scope: ['#{SCOPE_1}#', '#{SCOPE_2}#', '#{SCOPE_3}#'],
  authority: '#{AUTHORITY}#',
  redirect_url: '#{REDIRECT_URL}#',
  ui_locales: 'en-US',
  // Policy server config
  policy_url: '#{POLICY_URL}#',
  // Api URL
  baseUrl: '#{BASEURL}#',
  // Used to load images from server
  base_host_url: '#{FILE_HOST_URL}#',
  authorityDomain: '#{AUTHORITYDOMAIN}#',
  signUpAuthority: '#{SIGNUPAUTHORITY}#',
  userPortalBaseUrl: '#{USERPORTALBASEURL}#',
  change_password_url: '#{CHANGEPASSWORDURL}#',
  // current API version
  api_version: '1.0',
};
