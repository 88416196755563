import { ModuleWithProviders, NgModule } from '@angular/core';

import { AuthPolicyService, AUTH_POLICY_ENV } from './auth-policy-service/auth-policy.service';
import { HasPermissionDirective } from './directives/has-permission/has-permission.directive';
import { HasRoleDirective } from './directives/has-role/has-role.directive';
import { PolicyEnvironment } from './model/policy-environment.model';
import { CommonModule } from '@angular/common';

/**
 * Auth policy module used for providing following;
 * 1) policy-service
 * 2) policy-directives
 * -> 1) hasPermission:
 * -> 2) hasRole:
 */
@NgModule({
  declarations: [
    HasPermissionDirective,
    HasRoleDirective,
  ],
  imports: [CommonModule],
  exports: [
    HasPermissionDirective,
    HasRoleDirective,
  ],
  providers:[AuthPolicyService]
})
export class AuthPolicyModule {

  /**
   * Static Configuration for Module
   * @param policyEnv Policy Environment
   */
  public static forRoot (policyEnv?: PolicyEnvironment): ModuleWithProviders<PolicyEnvironment> {
    return<any> {
      ngModule: AuthPolicyModule,
      providers: [
        {
          provide: AUTH_POLICY_ENV,
          useValue: policyEnv,
        },
        AuthPolicyService,
      ],
    };
  }
}
