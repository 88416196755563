import { DatePipe, DecimalPipe, TitleCasePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AuthPolicyModule, CoreModule, StorageType } from 'common-libs';
// --------------------------------------------------------------------
import { environment } from '../../environments/environment';
import { MasterComponent } from './components/master/master.component';
import { ActivateDashboardGuard } from './guards/activate-dashboard/activate-dashboard.guard';
import { CheckArchivedGuard } from './guards/check-archived/check-archived.guard';
import { CheckLicensingGuard } from './guards/check-licensing.guard';
import { CheckUserProfileGuard } from './guards/check-user-profile.guard';
import { BreadcrumbModule } from './module/breadcrumb/breadcrumb.module';
import { AppResolverService } from './resolvers/app.resolver';
import { UserInfoResolverService } from './resolvers/user-info.resolver';
import { UserProfileResolverService } from './resolvers/user-profile.resolver';



@NgModule({
  declarations: [
    MasterComponent,
  ],
  imports: [
    RouterModule,
    CoreModule,
    AuthPolicyModule,
    TabsModule.forRoot(),
    BreadcrumbModule.forRoot(),
  ],
  providers: [
    AppResolverService,
    UserInfoResolverService,
    UserProfileResolverService,
    CheckUserProfileGuard,
    CheckLicensingGuard,
    CheckArchivedGuard,
    DecimalPipe,
    DatePipe,
    TitleCasePipe,
    ActivateDashboardGuard,
  ]
})
export class AppCoreModule { }
