
import { Component, AfterViewInit } from '@angular/core';
import {  MsalService } from '@azure/msal-angular';
import { LoaderService, AuthService, PolicyData, AuthPolicyService } from 'common-libs';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
   /** This variable hold the application title name */
  public title:string = 'user-portal';

  /** This variable hold the boolean value */
  public showLoader: boolean = false;
  constructor(
    private loaderService: LoaderService,
    private authService: AuthService,
    private msalsrv: MsalService,
    private policyService: AuthPolicyService,

  ) {

    this.msalsrv.handleRedirectObservable().subscribe((redirectResponse) => {
      if (redirectResponse != null) {
        // Acquire token silent success
        this.authService.setUserData(redirectResponse as any);
        this.policyService.loadPolicyData().subscribe(((policyData: PolicyData) =>
          this.policyService.setPolicyData(policyData)))
      }
    })
  }

  /** Invoke this lifeCycle after mount dom */
  public ngAfterViewInit(): void {
    this.loaderService.status.subscribe((val: boolean) => {
      setTimeout(
        () => {
          this.showLoader = val;
        },
        100);
    });
  }
}
