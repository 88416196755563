/** Client Master */
export class BaseClientMaster {
  /** clientId */
  public clientId: number;
  /** client */
  public client: string;
  /** tenants */
  public tenants?: boolean;
  /** Account Number */
  public accountNumber?: boolean;
  /** logo small */
  public logoFileNameSmall?: string;
  /** logo large */
  public logoFileNameLarge?: string;
  /** product licensing for app-features */
  public productLicense?: ProductLicense;
  /** product licensing for app-features */
  public archive?: Archive;
}
/** Product licensing for app-features */
export class ProductLicense {
  public document?: boolean;
  constructor(
    document?:boolean
  ){
    this.document = document;
  }
}

/** Archived app-features */
export class Archive {
  public document?: boolean;
  constructor(
    document?:boolean
  ){
    this.document = document;
  }
}
