import { Injectable } from '@angular/core';
// import { Office, Timezone, Floor, UserType, Client, WeekDays } from '../model/common.model';
import { BaseResponse, HttpService } from 'common-libs';
import { environment } from '../../../environments/environment';
import {
  ClientMaster, FloorMaster, OfficeMaster, RoleMaster, TimezoneMaster,
  WeekDays, FloorResponseMaster, AssignedToMaster, UserWithRoleMaster, UsersMaster
} from '../model/common.model';
import { UserInfo } from '../model/core.model';
import { CoreDataService } from './core-data.service';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';
import { tap } from 'rxjs/internal/operators/tap';

@Injectable({
  providedIn: 'root'
})
export class CommonHttpService {

  /** it will used to store the base api url */
  private baseUrl: string;
  private readonly API_VERSION: string = environment.api_version;

  constructor(
    private httpService: HttpService,
    private coreDataService: CoreDataService
  ) {
    this.baseUrl = environment.baseUrl;
  }



  /** it will return the list of user type from server */
  public getRoles(): Observable<RoleMaster[]> {
    const url: string = this.baseUrl + 'roles';
    return this.httpService.httpGetRequest<RoleMaster[]>(url, this.API_VERSION)
      .pipe(map((data: BaseResponse<RoleMaster[]>) => data.result));
  }




  /** it will return the current login user info from server */
  public getLoginUserInfo(): Observable<UserInfo> {
    const url: string = this.baseUrl + 'users/information';
    // const url: string = this.baseUrl + 'information';
    return this.httpService.httpGetRequest<UserInfo>(url, this.API_VERSION)
      .pipe(map((data: BaseResponse<UserInfo>) => {
        return data.result;
      }));
  }

  /**
   * Load loggedIn user info
   */
  public loadLoggedInUserInfo(): Observable<UserInfo> {
    return this.getLoginUserInfo().pipe(
      tap((userInfo: UserInfo) => {
        return this.coreDataService.setUserInfo(userInfo)
      }));
  }

}
