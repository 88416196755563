import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateChild, Route, UrlSegment, CanActivate, CanLoad } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
// -------------------------------------- //
import { AuthService } from '../auth/auth.service';
import { of } from 'rxjs/internal/observable/of';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { MsalService } from '@azure/msal-angular';
import { take } from 'rxjs/operators';


/**
 * AuthGuard
 */
@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {

  constructor(
    private authService: AuthService
  ) { }
  /** canActivate route if pass policy check details */
  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkAuthentication();
  }
  /** canActivateChild route if pass policy check details */
  public canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkAuthentication();
  }
  /** canLoad route if pass policy check details */
  public canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkAuthentication();
  }

  /** canActivate */
  private async checkAuthentication(): Promise<boolean> {

    return this.authService.getUserData().pipe(
      take(1),
      switchMap((user: any) => {
        if (user && user.accessToken) {
          return of(true);
        } else {
          return of(true);

        }
      })
    ).toPromise();

  }

}
