import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
// ------------------------------------------------------ //
import { OverlayModule } from '@angular/cdk/overlay';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ToastrModule } from 'ngx-toastr';
import { BreadcrumbComponent } from './component/breadcrumb/breadcrumb.component';
import { PageTitleComponent } from './component/page-title/page-title.component';
import { ConfirmationModalService } from './services/confirmation-modal/confirmation-modal.service';
import { AuthGuard } from './services/guard/auth.guard';
import { interceptorProviders } from './services/interceptor/interceptors';
import { LoaderService } from './services/loader/loader.service';

import { HttpClientModule } from '@angular/common/http';
import { NgbTimeStringAdapter } from './adapter/timepicker.adapter';
import { TopbarService } from './services/topbar/topbar.service';

import { LayoutModule } from '@angular/cdk/layout';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthPolicyModule } from '../auth-policy.module';
import { SharedModule } from '../shared/shared.module';
import { NotificationAdapter, UpdateNotificationAdapter } from './adapter/notification-adapter';
import { CustomToastComponent } from './component/custom-toastr/custom-toastr.component';
import { LogoutComponent } from './component/logout/logout.component';
import { SidebarPresentationComponent } from './component/sidebar/sidebar-presentation/sidebar.presentation';
import { TopbarPresentationComponent } from './component/topbar/topbar-presentation/topbar.presentation';
import { AuthService } from './services/auth/auth.service';
import { BreakPointObserverService } from './services/break-point-observer/break-point-observer.service';
import { AuthPolicyGuard } from './services/guard/auth-policy.guard';
import { HelpContentDataService } from './services/help-content/help-content-data.service';
import { HttpService } from './services/http/http.service';
import { BusyService } from './services/interceptor/busy.service';
import { LanguageDataService } from './services/language/language-data.service';
import { MenuDataPolicyService } from './services/menu/menu-data-policy.service';
import { MenuDataService } from './services/menu/menu-data.service';
import { SidebarService } from './services/sidebar/sidebar.service';
import { ToastrServiceProvider } from './services/toaster/toastr.service';

/**
 * CoreModule
 */
@NgModule({
  declarations: [
    PageTitleComponent,
    BreadcrumbComponent,
    TopbarPresentationComponent,
    SidebarPresentationComponent,
    LogoutComponent,
    CustomToastComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    NgbDropdownModule,
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    TimepickerModule.forRoot(),
    ToastrModule.forRoot({
      toastComponent: CustomToastComponent,
      timeOut: 3000,
      preventDuplicates: true,
      enableHtml: true,
      // autoDismiss: false,
      // disableTimeOut: true
    }),
    OverlayModule,
    LayoutModule,
    NgSelectModule,
    TooltipModule.forRoot(),
    SharedModule,
    AuthPolicyModule,
    BrowserAnimationsModule
  ],
  providers: [
    ToastrServiceProvider,
    AuthGuard,
    AuthPolicyGuard,
    interceptorProviders,
    LoaderService,
    HelpContentDataService,
    MenuDataService,
    MenuDataPolicyService,
    TopbarService,
    SidebarService,
    HttpService,
    LanguageDataService,
    ConfirmationModalService,
    AuthService,
    BreakPointObserverService,
    NgbTimeStringAdapter,
    NotificationAdapter,
    UpdateNotificationAdapter,
    BusyService,
  ],
  exports: [
    CommonModule,
    RouterModule,
    BsDatepickerModule,
    TimepickerModule,
    BreadcrumbComponent,
    PageTitleComponent,
    TopbarPresentationComponent,
    SidebarPresentationComponent,
    LogoutComponent,
    AuthPolicyModule,
  ],
  entryComponents: [
    CustomToastComponent
  ]

})
export class CoreModule {

  /**
   * For root
   * @param environment
   * @returns root
   */
  // public static forRoot(environment: any): ModuleWithProviders<any> {
  //   return<any> {
  //     ngModule: CoreModule,
  //     providers: [
  //       ToastrServiceProvider,
  //       AuthGuard,
  //       AuthPolicyGuard,
  //       interceptorProviders,
  //       LoaderService,
  //       HelpContentDataService,
  //       MenuDataService,
  //       MenuDataPolicyService,
  //       TopbarService,
  //       SidebarService,
  //       HttpService,
  //       LanguageDataService,
  //       ConfirmationModalService,
  //       AuthService,
  //       BreakPointObserverService,
  //       NgbTimeStringAdapter,
  //       NotificationAdapter,
  //       UpdateNotificationAdapter,
  //       BusyService,
  //       {
  //         provide: 'environment',
  //         useValue: environment
  //       }
  //     ],
  //   };
  // }

}
